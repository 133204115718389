import React, { ChangeEvent, cloneElement } from 'react';
import classNames from 'classnames';
import { styled, makeStyles } from '../../styles';

function filterProps(props: any) {
  const { margin, ...rest } = props;
  return rest;
}

const StyledInputContainer = styled(props => <div {...filterProps(props)} />)(
  ({ theme: { palette, spacing }, margin }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: spacing(4),
    margin: margin ? spacing(1.5, 0) : 'none',
    appearance: 'none',
    background: palette.background.default,
    borderRadius: spacing(0.5),
    border: `1px solid ${palette.grey.lightTint}`,
    color: palette.grey.offBlack,
    '&:focus-within': {
      border: `1px solid ${palette.secondary.main}`,
    },
  }),
);

const StyledInput = styled('input')(({ theme: { palette, spacing } }: any) => ({
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  padding: spacing(0.5, 1),
  appearance: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  borderRadius: spacing(0.5),
  color: palette.text.main,
  '&:focus': {
    outline: 'none',
  },
}));

const useStyles = makeStyles(({ spacing, palette }: any) => ({
  endIcon: {
    color: palette.text.secondary,
    marginRight: spacing(),
  },
  startIcon: {
    padding: spacing(0.25),
    marginLeft: spacing(),
  },
}));

interface TypedInputProps {
  /** Props passed directly to the input element for fine grained controll */
  InputProps?: React.PropsWithoutRef<JSX.IntrinsicElements['input']>;
  /** Removes the default top and bottom margin */
  margin?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: any) => void;
  value?: string;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  id?: string;
  type: 'text' | 'email' | 'password' | 'number';
  name?: string;
  defaultValue?: string;
  required?: boolean;
  className?: string;
  inputClassName?: string;
}

function TypedInput({
  InputProps,
  margin,
  placeholder,
  startIcon,
  endIcon,
  onChange,
  onKeyPress,
  value,
  className,
  id,
  name,
  type,
  defaultValue,
  required,
  inputClassName,
}: TypedInputProps): React.ReactElement {
  const classes = useStyles();

  let EndIcon: React.ReactElement | null = null;
  let StartIcon: React.ReactElement | null = null;
  if (endIcon) {
    EndIcon = cloneElement(endIcon, {
      className: classNames(endIcon?.props.className, classes.endIcon),
    });
  }

  if (startIcon) {
    StartIcon = cloneElement(startIcon, {
      className: classNames(endIcon?.props.className, classes.startIcon),
    });
  }

  return (
    <StyledInputContainer margin={margin} className={className}>
      {StartIcon}
      <StyledInput
        autoFocus
        className={inputClassName}
        id={id}
        name={name}
        type={type}
        defaultValue={defaultValue}
        required={required}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
        placeholder={placeholder}
        {...InputProps}
      />
      {EndIcon}
    </StyledInputContainer>
  );
}

export default TypedInput;
