import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { IntlProvider, addLocaleData } from 'react-intl';
import ru from 'react-intl/locale-data/ru';
import pt from 'react-intl/locale-data/pt';
import uk from 'react-intl/locale-data/uk';
import { UnitConversionProvider } from 'libraries/unitConversion';
import { Language } from 'libraries/unitConversion/unit-constants';
import enMessages from '../../../../i18n-messages/dist/en-uk.json';

addLocaleData([...ru, ...pt, ...uk]);

const SKIP_DEFAULT_ERROR_PATTERN = '"en-UK", using default message as fallback';

function ConfiguredIntlProvider({ children }: { children?: React.ReactNode }) {
  const language = useSelector<{ language: Language }, Language>(
    state => state.language,
  );
  const unit_system = 'metric'
  const [messages, setMessages] = useState(enMessages);

  useEffect(() => {
    async function fetchMessages() {
      try {
        const nextMessages = await import(
          /* webpackChunkName: "translation" */
          `../../../../i18n-messages/dist/${language.toLowerCase()}.json`
        );
        setMessages(nextMessages.default);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Dynamic lang messges load failure: ', error);
      }

      return null;
    }

    if (language !== 'en-UK') {
      fetchMessages();
    } else {
      setMessages(enMessages);
    }
  }, [language]);

  return (
    <IntlProvider
      locale={language}
      messages={{ ...enMessages, ...messages }}
      onError={error => {
        if (!error.includes(SKIP_DEFAULT_ERROR_PATTERN)) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }}
    >
      <UnitConversionProvider
        language={language}
        unitSystem={unit_system}
        defaultSystem={{ language: 'en-UK', unitSystem: 'metric' }}
      >
        {children}
      </UnitConversionProvider>
    </IntlProvider>
  );
}

ConfiguredIntlProvider.propTypes = {
  children: PropTypes.element,
};

export default ConfiguredIntlProvider;
