import createTheme from './createTheme';

const defaultTheme = createTheme({
  palette: {
    // primary: {
    //   main: '#2093ce',
    //   light: '#edfcff',
    //   disabled: '#2093ce40',
    // },
    secondary: {
      // --hb-darkblue
      dark: '#00659d',
      // --hb-blue --c-body-bg
      main: '#2093ce',
      // --hb-creamy-blue
      light: '#66c3ff',
    },
    warning: {
      // --c-warning
      main: `#ec9d56`,
    },
    info: {
      // --c-info
      main: '#d8e9ed',
    },
    success: {
      // --c-success
      main: '#4f9053',
    },
    text: {
      // --c-text-light
      light: '#fff',
      // --c-text-main
      main: '#3d4344',
      // --c-text-secondary
      secondary: '#7a8587',
      // --c-text-minor
      minor: '#999',
      // --c-text-tint
      tint: '#aaa',
    },
    background: {
      // --c-content-bg
      default: 'white',
      // --c-highlight-bg
      highlight: '#f2fcfd',
      map: '#688968',
    },
    grey: {
      // --hb-gray
      main: '#bbcbce',
      // --c-tint
      tint: '#7a8587',
      lightTint: '#DADADA',
      // --hb-light-grey
      light: '#f9f9f9',
      // --c-tint-lighter
      lighter: '#edfcff',
      // --c-tint-lightest
      lightest: '#f7feff',
      cloudMask: '#767d7f',
      // --hb-off-black
      offBlack: '#3d4344',
      // --hb-transparent-black
      transparentBlack: '#00000030',
      black: '#000',
    },
    mapFeatures: {
      crops: '#1D7B09',
      livestock: '#262800',
    },
    action: {
      // --c-selected
      selected: '#aaeeff',
      disabled: '#2093ce40',
      hover: '',
    },
  },
  componentSizing: {
    accountFormWidth: '395px',
    mapLeftPanelWidth: 320,
    mapLeftPanelHeaderHeight: 182,
    mapLeftPanelZoneHeaderHeight: 216,
    mapLeftPanelFooterHeight: 56,
    mapBottomPanelHeight: 320,
    modalWidth: '496px',
    navBar: 56,
    tooltipWidth: 412,
  },
  shadows: [
    '0px 3px 6px #00000029',
    '0px 3px 6px #00000080',
    '0px 6px 6px #00000029',
    '0px 6px 6px #00000080',
    '0px -2px 3px 0px  #00000029',
    '2px -2px 3px 0px  #00000029',
    '2px 0px 3px 1px  #00000029',
  ],
  zIndex: {
    leafletMax: 1000,
    tooltip: 1100,
  },
  typography: {
    fontFamily: '"Roboto", "Arial", sans-serif',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  transition: {
    duration: 350,
    timingFunction: 'ease-in',
  },
});

export default defaultTheme;
