import { combineReducers } from 'redux';

import languageReducer from 'libraries/i18n/reduxState/languageReducer';
import apiRequestReducer from 'services/api/apiRequestReducer';
import selectedFieldSurveyReducer from 'reduxState/field/selectedFieldSurveyReducer';
import networkStatusReducer from 'services/network/networkStatusReducer';

/**
 * NOTE: ENSURE EACH SECTION IS SORTED ALPHABETICALLY WHEN ADDING NEW REDUCERS
 */
const mapsReducer = {
  apiRequests: apiRequestReducer,
  language: languageReducer,
  networkOnline: networkStatusReducer,
  selectedFieldSurvey: selectedFieldSurveyReducer,
};

const appReducer = combineReducers({
  ...mapsReducer,
});

const rootReducer = (state, action) => {
  let appState = state;

  // ensure state is cleared on logout, or a fresh login
  if (action.type === 'USER_LOGOUT' || action.type === 'USER_AUTHENTICATE') {
    appState = {
      language: state.language,
    };
  }

  return appReducer(appState, action);
};

export default rootReducer;
