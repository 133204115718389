import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useAuth } from '@hummingbirdtechgroup/wings-auth';
import { useConfig } from '@hummingbirdtechgroup/wings-config';

function useLocation() {
  const url = window.location.pathname + window.location.search;

  return url;
}

const getRole = (user) => (user.staff ? 'STAFF' : user.clients?.[0].role);

function getCompanyFromEmail(email) {
  if (!email) return null;
  const domains = email.split('@');
  const company = domains[1].split('.')[0];

  return company;
}

function GoogleAnalytics() {
  const {user} = useAuth();
  const url = useLocation();

  const { platformAnalyticsCode } = useConfig();

  useEffect(() => {
    if (user) {
      const company = getCompanyFromEmail(user.email);
      ReactGA.initialize(platformAnalyticsCode, {
        // set this to true when debuggin in the console.
        debug: false,
        gaOptions: {
          userId: user.uid,
        },
      });
      ReactGA.set({
        dimension1: user.uid,
        dimension2: getRole(user),
        dimension3: company,
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const company = getCompanyFromEmail(user.email);
      ReactGA.set({
        dimension1: user.uid,
        dimension2: getRole(user),
        dimension3: company,
      });
      ReactGA.pageview(url);
    }
  }, [url, user]);

  return null;
}

export default GoogleAnalytics;
