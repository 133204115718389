import React, { Suspense } from 'react';
import { Route, SentryRoute, Switch, Redirect } from 'libraries/wings-router';
import { SuspenseFallback } from 'libraries/wings-ui/components';
import {
  LoginRedirect,
  LogoutRedirect,
  PrivateRoute,
} from '@hummingbirdtechgroup/wings-auth';

/** =====================
 *  PRIMARY COMPONENTS
 * ====================== */

const AppLayoutLoggedIn = React.lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: 'AppLayoutLoggedIn' */
      './components/AppLayoutLoggedIn'
      ),
);
const FieldsMap = React.lazy(
  () =>
    import(
      /* webpackPreload: true */ /*  webpackChunkName: 'FieldsMap' */ '../modules/FieldsMap'
      ),
);
const Analytics = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: 'Analytics' */ '../modules/Analytics'
      ),
);
const Admin = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: 'Admin' */ '../modules/Admin'
      ),
);

const PrintRouter = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ /* webpackChunkName: 'Print' */ '../modules/Print'
      ),
);

/** ======================
 *  SECONDARY COMPONENTS
 * ======================= */

const NotAuthorized = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'NotAuthorized' */
      './components/NotAuthorized'
      ),
);

const FarmAtHandVerification = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'FarmAtHandVerification' */ './components/FarmAtHandVerification'
      ),
);

const NotFound = React.lazy(
  () => import(/* webpackChunkName: 'NotFound' */ './components/NotFound'),
);

function AppRouter(): React.ReactElement {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Switch>
        <Route exact path="/login">
          <LoginRedirect />
        </Route>
        <Route exact path="/logout">
          <LogoutRedirect />
        </Route>

        <PrivateRoute path="/connect/farm-at-hand">
          <FarmAtHandVerification />
        </PrivateRoute>

        <SentryRoute path="/not-authorized" component={NotAuthorized} />

        <PrivateRoute path="/print">
          <PrintRouter />
        </PrivateRoute>
        {/* PRIMARY APPLICATION MODULES */}
        <PrivateRoute path="/">
          <AppLayoutLoggedIn>
            <Switch>
              <PrivateRoute path="/analytics">
                <Analytics />
              </PrivateRoute>

              <PrivateRoute path="/admin">
                <Admin />
              </PrivateRoute>

              <PrivateRoute path="/map/:farmId?">
                <FieldsMap />
              </PrivateRoute>
              <Redirect from="/" to="/map" exact />
              <Route path="*" component={NotFound} status={404} />
            </Switch>
          </AppLayoutLoggedIn>
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
}

export default AppRouter;
