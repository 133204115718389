import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { useAuth } from '@hummingbirdtechgroup/wings-auth';

function getCompanyFromEmail(email) {
  if (!email) return null;
  const domains = email.split('@');
  const company = domains[1].split('.')[0];

  return company;
}

const getRole = (user) => (user.staff ? 'STAFF' : user.clients?.[0].role);

function SentryScope() {
  const {user} = useAuth();
  const language = useSelector(state => state.language);

  useEffect(() => {
    const company = getCompanyFromEmail(user?.email);
    Sentry.configureScope(scope => {
      scope.setUser({ email: user?.email, username: user?.name, id: user?.uid });
      scope.setTag('user_role', getRole(user));
      scope.setTag('page_locale', language);
      scope.setExtra('user_company', company);
    });
  }, [user, language]);

  return null;
}

export default SentryScope;
