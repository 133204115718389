import { useCallback, useDebugValue } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'qs';

type SetQueryOptions = {
  /** Will merge the existing query parameters with the new ones */
  merge?: boolean;
  delete?: Array<string>;
};

/**
 * Lightweight hook to simplify updating the query parameters in a url
 * It should not be used to navigate between views but rather to update state
 * Think of it as a useState hook.
 *
 * It always replaces the current url rather than pushing a new one. This means
 * a user won't need to click back hundreds of times to get to the previous page
 */
function useSearchParams(): readonly [
  Record<string, any>,
  (
    /** provide the new queryParams or a function which will recieve the current params as an argument */
    queryParams:
      | Record<any, any>
      | ((currentQuery: Record<any, any>) => Record<any, string | null>),
    opts?: SetQueryOptions,
  ) => void,
] {
  const history = useHistory();
  // @ts-ignore
  const { query: _queryString } = useLocation();
  useDebugValue(`Current search params ${_queryString}`);

  const setQueryString = useCallback(
    (
      queryParams:
        | Record<any, any>
        | ((currentQuery: Record<any, any>) => Record<any, string | null>),
      opts?: SetQueryOptions,
    ) => {
      /**
       *  Manually parse the QS to avoid having _queryString in the deps array.
       *  This allows the function to be used  safely in a useEffect or useCallback
       *  without causing further loops.
       */
      const currentSearch = parse(window.location.search, {
        ignoreQueryPrefix: true,
      });

      const parameters =
        typeof queryParams === 'function'
          ? queryParams(currentSearch)
          : queryParams;

      const nextQueryString = opts?.merge
        ? { ...currentSearch, ...parameters }
        : parameters;

      opts?.delete?.forEach(deleteItem => delete nextQueryString[deleteItem]);

      // @ts-ignore
      history.replace({ query: nextQueryString });
    },
    [history],
  );

  return [_queryString, setQueryString] as const;
}

export default useSearchParams;
