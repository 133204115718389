import { hijackEffectHook } from 'stop-runaway-react-effects';

import React from 'react';
import ReactDOM from 'react-dom';
import { hotjar } from 'react-hotjar';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { browserHistory } from 'libraries/wings-router';

import 'services/hideaddrbar';
import 'intersection-observer';
import 'fix-date';
import App from './App';

const sentryTracing = new TracingIntegrations.BrowserTracing({
  routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory),
});

if (process.env.NODE_ENV === 'development') {
  // doubled the defaults to avoid crashes on range slider updates
  hijackEffectHook('useLayoutEffect', { callCount: 120, timeLimit: 2000 });
}

if (process.env.NODE_ENV !== 'development') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  hotjar.initialize(1705104);

  const environment = `${process.env.NODE_ENV}-${process.env.API_ENV}`;

  Sentry.init({
    dsn:
      'https://6e48cd2a01a74dcca50ff9866a6def9a@o365832.ingest.sentry.io/5128221',
    environment,
    // @ts-ignore [ seems to be an issue with the types from sentry as this is the setup in the docs ]
    integrations: [sentryTracing],
    tracesSampleRate: 0.5,
    ignoreErrors: [
      "Cannot read property '_fadeAnimated' of null",
      "Cannot read property 'bottomright' of undefined",
      "Cannot read property '_leaflet_pos' of undefined",
      "Unable to get property '_leaflet_pos' of undefined or null reference",
      "Cannot set property '_leaflet_pos' of undefined",
      // https://hummingbirdtech.atlassian.net/browse/FE-25
      'L.current is null',
      // https://hummingbirdtech.atlassian.net/browse/AB-117
      'Failed to fetch',
      // https://hummingbirdtech.atlassian.net/browse/AB-1027
      "Cannot read property 'unproject' of null",
      // https://hummingbirdtech.atlassian.net/browse/FE-147
      'feature2 MultiPolygon geometry not supported',
      "Cannot read properties of null (reading '_controlCorners')",
      "Cannot read properties of null (reading 'getBoundingClientRect')",
    ],
    // todo: filter out errors from npm packages
  });
}
/**
 * This is the root of our application. Only the App component should be used here
 *
 * If you need to register globally available Providers please do so in /App/containers/App in the AppWithProviders component
 */
ReactDOM.render(<App />, document.getElementById('main'));
